:root {
  --spacing-xxs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-s: 0.75rem;
  --spacing-m: 1.5rem;
  --spacing-l: 2rem;
  --spacing-xl: 3.5rem;
  --spacing-xxl: 5rem;
  --spacing-xxxl: 7.5rem;
  --measure-xs: 14rem;
  --measure-s: 21.5rem;
  --measure-m: 33rem;
  --measure-l: 44.5rem;
  --measure-xl: 67.5rem;

  --navbar-height: 3.5rem;
  --sidebar-width: calc(100% - 3.75rem);

  --z-background: -1;
  --z-content: 1;
  --z-navbar: 10;
  --z-sidebar: 20;
  --z-popup: 30;
}

.mt-xxs {
  margin-top: var(--spacing-xxs);
}
.pt-xxs {
  padding-top: var(--spacing-xxs);
}
.mt-xs {
  margin-top: var(--spacing-xs);
}
.pt-xs {
  padding-top: var(--spacing-xs);
}
.mt-s {
  margin-top: var(--spacing-s);
}
.pt-s {
  padding-top: var(--spacing-s);
}
.mt-m {
  margin-top: var(--spacing-m);
}
.pt-m {
  padding-top: var(--spacing-m);
}
.mt-l {
  margin-top: var(--spacing-l);
}
.pt-l {
  padding-top: var(--spacing-l);
}
.mt-xl {
  margin-top: var(--spacing-xl);
}
.pt-xl {
  padding-top: var(--spacing-xl);
}
.mt-xxl {
  margin-top: var(--spacing-xxl);
}
.pt-xxl {
  padding-top: var(--spacing-xxl);
}
.mt-xxxl {
  margin-top: var(--spacing-xxxl);
}
.pt-xxxl {
  padding-top: var(--spacing-xxxl);
}

.mb-xxs {
  margin-bottom: var(--spacing-xxs);
}
.pb-xxs {
  padding-bottom: var(--spacing-xxs);
}
.mb-xs {
  margin-bottom: var(--spacing-xs);
}
.pb-xs {
  padding-bottom: var(--spacing-xs);
}
.mb-s {
  margin-bottom: var(--spacing-s);
}
.pb-s {
  padding-bottom: var(--spacing-s);
}
.mb-m {
  margin-bottom: var(--spacing-m);
}
.pb-m {
  padding-bottom: var(--spacing-m);
}
.mb-l {
  margin-bottom: var(--spacing-l);
}
.pb-l {
  padding-bottom: var(--spacing-l);
}
.mb-xl {
  margin-bottom: var(--spacing-xl);
}
.pb-xl {
  padding-bottom: var(--spacing-xl);
}
.mb-xxl {
  margin-bottom: var(--spacing-xxl);
}
.pb-xxl {
  padding-bottom: var(--spacing-xxl);
}
.mb-xxxl {
  margin-bottom: var(--spacing-xxxl);
}
.pb-xxxl {
  padding-bottom: var(--spacing-xxxl);
}

.ml-xxs {
  margin-left: var(--spacing-xxs);
}
.pl-xxs {
  padding-left: var(--spacing-xxs);
}
.ml-xs {
  margin-left: var(--spacing-xs);
}
.pl-xs {
  padding-left: var(--spacing-xs);
}
.ml-s {
  margin-left: var(--spacing-s);
}
.pl-s {
  padding-left: var(--spacing-s);
}
.ml-m {
  margin-left: var(--spacing-m);
}
.pl-m {
  padding-left: var(--spacing-m);
}
.ml-l {
  margin-left: var(--spacing-l);
}
.pl-l {
  padding-left: var(--spacing-l);
}
.ml-xl {
  margin-left: var(--spacing-xl);
}
.pl-xl {
  padding-left: var(--spacing-xl);
}
.ml-xxl {
  margin-left: var(--spacing-xxl);
}
.pl-xxl {
  padding-left: var(--spacing-xxl);
}
.ml-xxxl {
  margin-left: var(--spacing-xxxl);
}
.pl-xxxl {
  padding-left: var(--spacing-xxxl);
}

.mr-xxs {
  margin-right: var(--spacing-xxs);
}
.pr-xxs {
  padding-right: var(--spacing-xxs);
}
.mr-xs {
  margin-right: var(--spacing-xs);
}
.pr-xs {
  padding-right: var(--spacing-xs);
}
.mr-s {
  margin-right: var(--spacing-s);
}
.pr-s {
  padding-right: var(--spacing-s);
}
.mr-m {
  margin-right: var(--spacing-m);
}
.pr-m {
  padding-right: var(--spacing-m);
}
.mr-l {
  margin-right: var(--spacing-l);
}
.pr-l {
  padding-right: var(--spacing-l);
}
.mr-xl {
  margin-right: var(--spacing-xl);
}
.pr-xl {
  padding-right: var(--spacing-xl);
}
.mr-xxl {
  margin-right: var(--spacing-xxl);
}
.pr-xxl {
  padding-right: var(--spacing-xxl);
}
.mr-xxxl {
  margin-right: var(--spacing-xxxl);
}
.pr-xxxl {
  padding-right: var(--spacing-xxxl);
}

.measure-xs {
  max-width: var(--measure-xs);
}
.measure-s {
  max-width: var(--measure-s);
}
.measure-m {
  max-width: var(--measure-m);
}
.measure-l {
  max-width: var(--measure-l);
}
.measure-xl {
  max-width: var(--measure-xl);
}

.flex-h {
  display: flex;
  flex-flow: row nowrap;
}

.flex-v {
  display: flex;
  flex-flow: column nowrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-self-start {
  align-self: flex-start;
}

.flex-fill {
  flex: 1;
}

.booking-form-position {
  position: relative;
  top: -2rem;
}
