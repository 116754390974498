:root {
  --radius-l: 7px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  margin: 0;
  background: inherit;
  border: none;
  color: inherit;
}

p {
  margin: 0;
  padding: 0;
}
