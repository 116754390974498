:root {
  --font-weight-bold: 800;
}

.decorative {
  font-family: Dosis, sans-serif;
  font-weight: 500;
}

h1 {
  font-family: Mulish;
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
}

h1.decorative {
  font-family: Dosis;
  font-size: 3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
}

h2 {
  font-family: Mulish;
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
}

h2.decorative {
  font-family: Dosis;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h3.decorative {
  font-family: Dosis;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h3 {
  font-family: Mulish;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
}

h4 {
  font-family: Mulish;
  font-size: 0.813rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
}

p {
  font-family: Mulish;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.primary {
  color: var(--color-primary);
}

.accent {
  color: var(--color-accent);
}

.white {
  color: var(--color-white);
}

.center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: var(--font-weight-bold);
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 1.25;
  }

  h1.decorative {
    font-size: 4rem;
  }

  h2 {
    font-size: 2rem;
    letter-spacing: 0.64px;
  }
}
