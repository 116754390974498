.footer {
  display: grid;
  grid-template:
    repeat(7, auto)
    / 1fr;
  grid-template-areas:
    'logo'
    'mission'
    'legal'
    'action'
    'links'
    'social'
    'copyright';
  gap: var(--spacing-m);
  justify-content: space-between;
  justify-items: start;
  background: var(--color-primary);
}

.logo {
  grid-area: logo;
}

.links {
  display: grid;
  grid-area: links;
  grid-auto-rows: auto;
  margin: var(--spacing-s) 0;
}

.link {
  margin-bottom: var(--spacing-s);
  padding: var(--spacing-s) 0;
  color: var(--color-white-800);
}

.link:hover {
  color: var(--var-white);
}

.mission,
.legal {
  grid-area: mission;
  max-width: var(--measure-s);
}

.legal {
  grid-area: legal;
}

.copyright {
  grid-area: copyright;
  justify-self: stretch;
  margin-top: var(--spacing-l);
  font-family: Mulish;
  font-size: 1rem;
  line-height: 1.4;
  border-top: 1px solid var(--color-white-300);
}

.action {
  grid-area: action;
}

.social {
  grid-area: social;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIcon {
  max-width: 32px;
  margin-right: 8px;
}

.facebookIcon {
  max-width: 44px;
}

@media screen and (min-width: 768px) {
  .footer {
    grid-template:
      repeat(4, auto)
      / 1fr repeat(3, auto) 1fr;
    grid-template-areas:
      '. logo links language .'
      '. mission links . .'
      '. legal links . .'
      '. action links . .'
      'social social social social social'
      'copyright copyright copyright copyright copyright';
    gap: var(--spacing-m);
    justify-content: space-between;
    justify-items: start;
  }

  .links {
    grid: repeat(4, auto) / auto-flow 14rem;
    padding-left: var(--spacing-xxxl);
    margin: 0;
  }

  .link {
    margin: 0;
  }

  .copyright {
    margin-top: var(--spacing-xxl);
  }
}
