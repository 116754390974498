.overlay {
  display: none;
  overflow: visible !important;
}

.overlay.active {
  display: block;
}

.sidebar {
  height: calc(100vh - var(--navbar-height));
}

.close {
  align-self: flex-end;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}

.close span {
  display: block;
  height: 1.25rem;
  width: 3px;
  margin: var(--spacing-xxs) 0;
  background: var(--color-white);
}

.close span:first-of-type {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  bottom: 0;
  transform: rotate(45deg);
}

.close span:last-of-type {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  bottom: 0;
  transform: rotate(-45deg);
}

.logo {
  margin-bottom: 32px;
  padding-left: 12px;
}
