:root {
  --color-white: #fff;
  --color-white-900: rgba(255, 255, 255, 0.97);
  --color-white-800: rgba(255, 255, 255, 0.8);
  --color-white-300: rgba(255, 255, 255, 0.3);
  --color-black: #000;
  --color-primary: #1e1e1e;
  --color-primary-800: rgba(30, 30, 30, 0.8);
  --color-primary-500: rgba(30, 30, 30, 0.5);
  --color-primary-100: rgba(30, 30, 30, 0.1);
  --color-secondary: #a3abbd;
  --color-secondary-300: #e3e6eb;
  --color-secondary-100: rgba(163, 171, 189, 0.13);
  --color-accent: rgb(0, 164, 166);
  --color-accent-700: #54bab5;
}
