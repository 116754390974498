.link {
  margin: var(--spacing-m) var(--spacing-m) 0 0;
  padding: var(--spacing-xs) var(--spacing-s);
  position: relative;
  font-size: 0.813rem;
  font-weight: var(--font-weight-bold);
  color: currentColor;
  letter-spacing: 0.26px;
}

.sidebar {
  width: var(--sidebar-width);
  background: var(--color-primary);
  z-index: var(--z-sidebar) !important;
}

.open {
  margin: var(--spacing-xs) 0;
  padding: 0 var(--spacing-xs);
  color: var(--color-white);
  z-index: var(--z-navbar);
}

.open .line {
  display: block;
  height: 3px;
  width: 24px;
  margin: var(--spacing-xxs) 0;
  background: var(--color-white);
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activeLink {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .link {
    margin: 0 var(--spacing-m) 0 0;
    padding: var(--spacing-s);
    color: var(--color-white-800);
  }

  .link::after {
    content: '';
    height: 2px;
    position: absolute;
    width: 0;
    left: 0;
    bottom: 0.125rem;
    background: currentColor;
    transition: width 0.26s ease-in;
  }

  .link:hover {
    color: var(--color-white);
  }

  .link:hover::after {
    width: 100%;
    transition: width 0.26s ease-in;
  }
}
