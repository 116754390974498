.header {
  position: fixed;
  width: 100%;
  max-height: var(--navbar-height);
  background: var(--color-primary);
  padding-left: var(--spacing-s);
  z-index: var(--z-navbar);
}

.nav {
  height: var(--navbar-height);
}

@media screen and (min-width: 768px) {
  .header {
    padding-left: var(--spacing-l);
  }
}
