.sidebar {
  background: var(--color-white);
  width: 100%;
  top: var(--navbar-height) !important;
}

.bookingForm {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navbar-height));
}

.bookingFormSlogan {
  margin-top: 32px;
  margin-bottom: 16px;
  padding-left: 8px;
}

.overlay {
  background: transparent;
}

.actionButton {
  height: 100%;
  padding: 0 var(--spacing-l);
  font-size: 0.813rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.26px;
  background: var(--color-accent);
  border-radius: 0;
  color: var(--color-white);
  transition: all 0.15s ease-in;
}
.actionButton.close {
  z-index: var(--z-sidebar);
}

@media screen and (min-width: 768px) {
  .actionButton:hover {
    background: var(--color-white);
    color: var(--color-primary);
    cursor: pointer;
    transition: all 0.15s ease-in;
  }
}

.field {
  background: var(--color-white);
}

.input {
  background: var(--color-secondary-300);
  border: none;
}

.bookingForm :global(.react-select__control) {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--color-secondary-300);
}

.bookingForm :global(.react-select__indicator-separator) {
  display: none;
}

.bookingForm :global(.react-select__option) {
  padding: var(--spacing-s) var(--spacing-s);
  line-height: 2rem;
  background: var(--color-secondary-100);
  border-bottom: 1px solid var(--color-secondary-300);
}

.bookingForm :global(.react-select__option--is-selected) {
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
}

.bookingForm :global(.react-select__menu) {
  position: relative;
  top: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--color-secondary-300);
  border-radius: 0;
  box-shadow: none;
}

.bookingForm :global(.react-select__menu-list) {
  max-height: unset;
  padding: 0;
}

.field :global(.react-select__control) {
  padding: var(--spacing-xs) 0;
}

.field:first-of-type :global(.react-select__control) {
  overflow: hidden;
}

.field :global(.react-select__control--is-focused),
.field :global(.react-select__control):hover {
  border-color: var(--color-secondary-300);
  box-shadow: none;
}
.field :global(.react-select__single-value),
.field :global(.react-select__placeholder) {
  padding-left: var(--spacing-l);
  color: var(--color-primary);
}

.sendButton {
  margin-top: auto;
  width: 100%;
  padding: var(--spacing-m) 0;
  font-size: 0.813rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.26px;
  background: var(--color-accent);
  color: var(--color-white);
  border-radius: 0;
}

.text {
  position: relative;
  min-height: 3.5rem;
}

.text span {
  color: var(--color-primary);
}

.text input,
.text textarea {
  padding: var(--spacing-s);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-secondary-100);
  font-size: 1rem;
}

.text textarea {
  height: auto;
}

.selectIcon {
  color: var(--color-primary);
}

.field :global(.react-select__control--menu-is-open) {
  background: var(--color-accent);
  color: var(--color-white);
}

.field
  :global(.react-select__control--menu-is-open)
  :global(.react-select__placeholder) {
  color: var(--color-white);
}

.field :global(.react-select__control--menu-is-open) .selectIcon {
  color: var(--color-white);
}

.text.field span.hidden {
  display: none;
}
