.button {
  padding: var(--spacing-s) var(--spacing-l);
  font-family: Mulish;
  font-size: 1rem;
  color: var(--color-white);
  line-height: 1.5;
  letter-spacing: 0.32px;
  border-radius: 30px;
}

.primary {
  background-color: var(--color-accent);
  border: 2px solid var(--color-accent);
}

.primary:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.secondary {
  color: var(--color-primary);
  background: var(--color-white);
  border: 2px solid var(--color-white);
}

.link {
  color: var(--color-primary);
  background: transparent;
  border-color: transparent;
}

.outline {
  background: transparent;
}

.outline.primary {
  color: var(--color-white);
  border-color: var(--color-white);
}

.outline.primary:hover {
  background: var(--color-white);
  color: var(--color-primary);
}
